:root
{
  --yellow: #F5C32C;
    --orange : #FCA61F;
    --black : #242D49;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

.App{
    padding: 0.5rem 3.5rem;
    overflow: hidden;
    color: var(--black);    
}

.btn{
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  padding: 11px 26px 11px 26px;
  background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
  box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.4);

}
/* body {
  overflow-y: hidden;  Hide vertical scrollbar 
  overflow-x: hidden;  Hide horizontal scrollbar 
} */
::-webkit-scrollbar{
  display: none;
}

.btn:hover{
  background: white;
  cursor: pointer;
  color: var(--orange);
  border: 2px solid var(--orange);
  box-shadow: none;
  font-weight: bold;
  transform: scale(1.1);
 
}

@media screen and (max-width: 480px){
    .App{
      padding: 0.5rem 1rem;
    }
   
    
}


  

  
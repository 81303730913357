.intro{
    display: flex;
    height: 77vh;
    margin-top: 6rem;

}
.i-left{
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    gap: 2rem;
}
.i-name{
    display: flex;
    flex-direction: column;
}

.i-name>:nth-child(1){
    color: var(--black);
    font-weight: bold;
    font-size: 3rem;
}
.i-name>:nth-child(2){
    color: var(--orange);
    font-weight: bold;
    font-size: 3rem;
}
.i-name>:nth-child(3){
    color: var(--gray);
    font-weight: 100;
    font-size: 20px;
    margin-top: 10px;
    line-height: 1.3rem;
}
.i-button{
    width: 6rem;
    height: 2rem;
}


.i-icons{
    margin-top: 2rem;
    display: flex;
    gap: 0;
}
.i-icons>*{
    transform: scale(0.5);

}
.i-icons>*:hover{
    cursor: pointer;
    transform: scale(0.6);

}

.i-right{
    display: flex;
    flex: 1;
    position: relative;
}
.i-right>*{
    position: absolute;
    z-index: 1;
}
.i-right>:nth-child(1){
    visibility: hidden;
    transform: scale(0.69);
    left: -15%;
    top: -9%;
}
.i-right>:nth-child(2){
    visibility: hidden;
    transform: scale(0.67);
    left: -3rem;
    top: -4.6rem;
}
.i-right>:nth-child(3){
    transform: scale(0.24);
    /* left: 28%; */
    top: -68rem;
    right: -57rem;
}
.i-right>:nth-child(4){
    transform: scale(0.6);
    left: -24%;
    top: -19%;
    border-radius: 50%;
    padding: 0;
}

.blur{
    position: absolute;
    width: 22rem;
    height: 14rem;
    border-radius: 50%;
    background: rgb(247, 178, 247);
    z-index: -9;
    top: -18%;
    left: 56%;
    filter: blur(60px);
}


@media screen and (max-width: 480px){
    .intro{
        flex-direction: column;
        height: 64rem;
        gap: 7rem;
    }
    .i-right{
        transform: scale(0.8);
        left: -3rem;
    }
    .i-right .blur{
        display: none;

    }
    .floating-div:nth-child(1){
        top: -7rem !important;
    }
    .floating-div-e{
        position: relative;
        margin-top: -9rem;
        right: 1rem;

    }
    .i-name {
        margin-top: -5rem;
    }
    .i-icons {
        
        margin-top: 1rem;
    }
    .i-right {
        transform: scale(0.8);
        left: -3rem;
        top: -10rem;
    }
    .i-right>:nth-child(3) {
        transform: scale(0.22);
        /* left: 28%; */
        top: -69rem;
        right: -68rem;
    }
}
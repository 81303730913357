.footer{
    display: flex;
    align-items: center;
    margin: -0.5rem -3.5rem;
    position: relative;
    margin-top: -3rem;
}

.f-content{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 5rem;
    gap: 2rem;
    color: white;
}
.f-content span{
    font-size: 30px;
}

.f-icons{
    display: flex;
    gap: 2rem;
}
.footer img{
    height: 60vh;
    width: 100vw;
}

@media screen and (max-width: 480px){
    .f-content{
        gap: 3rem;
        margin-top: -3rem;
    }
    .footer{
        margin-top: -6rem;
        height: 35vh;
    }
    .footer img{
        width: 140vw;
    top: -4rem;
    position: relative;
    }
}
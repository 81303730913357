.contact-form{
    padding: 0rem 3rem;
    display: flex;
    height: 100%;
    margin-top: -12rem;
}
.awesome-c{
    display: flex;
    flex-direction: column;
    position: relative;
    left: 5rem;

}

.c-right{
    display: flex;
    justify-content: center;
    position: relative;
    flex: 1;
}

.c-right>form{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    
}

.c-right .user{
    width: 20rem;
    height: 2rem;
    padding: 0.3rem;
    outline: none;
    border: 2px solid var(--orange);
    border-radius: 8px;
    font-size: 16px;
}

textarea{
    height: 4rem!important;
}
.c-blur1{
    top: 1rem;
    left: 8rem;
}

@media screen and (max-width: 480px){
    .contact-form{
        
        margin-top: 3rem;
    }
    .awesome-c{
        
        left: 0rem;
    
    }
    .awesome-c :nth-child(1){
        font-size: 2rem;
    }
    .awesome-c :nth-child(2){
        font-size: 2rem;
    }
    .awesome-c :nth-child(1) {
        font-size: 2rem;
        position: relative;
        top: -20rem;
    }
    .awesome-c :nth-child(2) {
        font-size: 2rem;
        position: relative;
        top: -20rem;
    }
    .c-right>form {
        
        right: 9rem;
        position: relative;
        top: -7rem;
    }
    .c-right .user {
        width: 14rem;
        
    }
}